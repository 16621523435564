import Joi from 'joi';

const stringRequired = Joi.string().required();
const emptyString = Joi.string().empty('');

export const customIframeFormSchema = Joi.object({
  title: stringRequired,
  src: stringRequired,
  width: emptyString,
  height: emptyString,
  referrerPolicy: stringRequired,
  sandbox: emptyString,
}).options({ stripUnknown: true });
