import React, { FC } from 'react';

import useJoiValidation from '../../hooks/useJoiValidation';
import { customIframeFormSchema } from './CustomIframeForm.schema';
import { ICustomIframeFormProps } from './models';

import './CustomIframeForm.scss';

const CustomIframeForm: FC<ICustomIframeFormProps> = ({
  title,
  src,
  width,
  height,
  referrerPolicy,
  sandbox,
}) => {
  const {
    data,
  }: {
    data: ICustomIframeFormProps;
  } = useJoiValidation(customIframeFormSchema, 'CustomIframeForm', {
    title,
    src,
    width,
    height,
    referrerPolicy,
    sandbox,
  });

  return (
    <div className="cds-section cds-page-block iframe-container cds-section--no-double-spacing cds-section--inverse-mode">
      <iframe
        title={data.title}
        src={data.src}
        width={data.width}
        height={data.height}
        referrerPolicy={data.referrerPolicy}
        {...(sandbox ? { sandbox: data.sandbox } : null)}
      />
    </div>
  );
};

export default CustomIframeForm;
